import React, { useEffect, useState } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'

// context
import { SiteContextProvider } from '@layout/context/siteContext'
import { UserContextProvider } from '@layout/context/userContext'

// hooks
import useReactQueryClient from '@hooks/useReactQueryClient'

// components
import Notification from '@layout/components/Notification'

export default function Layout({ children, location }) {
    const [notification, setNotification] = useState(null)
    const queryClient = useReactQueryClient(setNotification)

    useEffect(() => {
        setTimeout(() => {
            window.scroll({ top: -1, left: 0, behavior: 'smooth' })
        }, 10)
    }, [location])

    return (
        <QueryClientProvider client={queryClient}>
            <SiteContextProvider>
                <UserContextProvider>{children}</UserContextProvider>
            </SiteContextProvider>
            {notification && <Notification notification={notification} setNotification={setNotification} />}
        </QueryClientProvider>
    )
}
