import React from 'react'

const colorSettings = {
    neutral: {
        bgColor: 'bg-haze',
        ringColor: 'ring-haze',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    secondary: {
        bgColor: 'bg-emperor',
        ringColor: 'ring-emperor',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    tertiary: {
        bgColor: 'bg-white',
        ringColor: 'ring-emperor',
        borderColor: 'border-emperor',
        textColor: '!text-tundora',
    },
    error: {
        bgColor: 'bg-mandy',
        ringColor: 'ring-mandy',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    success: {
        bgColor: 'bg-success',
        ringColor: 'ring-success',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
    studio: {
        bgColor: 'bg-studio',
        ringColor: 'ring-studio',
        borderColor: 'border-transparent',
        textColor: '!text-white',
    },
}

const sizeSettings = {
    sm: {
        padding: 'px-2 py-1',
        fontSize: 'text-xs',
        iconSize: 'size-3',
        height: 'h-6',
    },
    md: {
        padding: 'px-3 py-1.5',
        fontSize: 'text-sm',
        iconSize: 'size-4',
        height: 'h-7',
    },
    base: {
        padding: 'px-3.5 py-2.5',
        fontSize: 'text-sm',
        iconSize: 'size-4',
        height: 'h-8',
    },
    lg: {
        padding: 'px-4 py-3',
        fontSize: 'text-base',
        iconSize: 'size-5',
        height: 'h-10',
    },
}

export default function Action({
    text,
    icon: Icon,
    as: DOMElement = 'button',
    type = 'button',
    mode,
    size = 'base',
    ...props
}) {
    const { bgColor, ringColor, borderColor, textColor } = colorSettings[mode ?? 'neutral']
    const { padding, fontSize, iconSize, height } = sizeSettings[size]

    return (
        <DOMElement
            {...props}
            type={type}
            className={`inline-flex cursor-pointer appearance-none items-center justify-center rounded border ${borderColor} ${bgColor} ${padding} shadow ${ringColor} ${height} hover:ring-1 hover:ring-offset-2 focus:outline-none disabled:cursor-not-allowed disabled:bg-plata disabled:ring-plata`}
        >
            {Icon && <Icon className={`mr-1 ${iconSize} ${textColor}`} aria-hidden='true' />}
            <span className={`font-lmp-regular ${fontSize} not-italic ${textColor}`}>{text}</span>
        </DOMElement>
    )
}
