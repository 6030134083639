import React, { createContext, useContext } from 'react'

// queries
import { useWebsiteContext } from '@layout/queries'

// components
import Spinner from '@components/Spinner'
import FullPageNotice from '@components/FullPageNotice'

// Create the context with a default value of null
const SiteContext = createContext(null)

// Hook to use it all over the app
export const useSiteCtx = () => {
    return useContext(SiteContext)
}

export const SiteContextProvider = ({ children }) => {
    const { data, isLoading, isError } = useWebsiteContext()

    if (isLoading) {
        return <Spinner mode='FULLSCREEN' />
    }

    let title = undefined
    let text = undefined

    if (isError) {
        title = 'Error de Servidor'
        text = 'Ocurrió un error en nuestros servidores; intentalo en unos minutos o verifica tu conexión a Internet.'
    }

    if (data === 'SITE_NOT_FOUND') {
        title = '404: Error'
        text =
            'El sitio que buscas no existe; comprueba la dirección o ponte en contacto con tu estudio para coordinar tu acceso.'
    }

    if (data === 'PLAN_EXPIRED') {
        title = 'Estudio Inactivo'
        text = 'El sitio está temporalmente inactivo; ponte en contacto con tu estudio para coordinar tu acceso.'
    }

    if (title !== undefined && text !== undefined) {
        return <FullPageNotice title={title} text={text} />
    }

    return <SiteContext.Provider value={data}>{children}</SiteContext.Provider>
}
