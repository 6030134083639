import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import fnFetch from '@functions/fnFetch'

export function useWebsiteContext() {
    return useQuery({
        queryKey: ['website', 'context'],
        queryFn: () => fnFetch('GET', '/website/context'),
        staleTime: 3.6e6, // 1 hour
    })
}

export function useUser() {
    return useQuery({
        queryKey: ['user'],
        queryFn: () => fnFetch('GET', '/identity'),
        staleTime: 15 * 60000, // 15 minutes
    })
}

export function useLogout() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => fnFetch('POST', '/logout'),
        onSuccess: async () => {
            queryClient.setQueryData(['user'], 'NO_SESSION')

            const preserveQueries = [
                JSON.stringify(['user']),
                JSON.stringify(['website', 'data']),
                JSON.stringify(['website', 'validation']),
            ]

            await queryClient.resetQueries({
                predicate: (query) => !preserveQueries.includes(JSON.stringify(query.queryKey)),
            })
        },
        meta: {
            skipSuccessMessage: true,
        },
    })
}
